@import url('https://use.typekit.net/tsb5azc.css');

body {
  margin: 0;
  height: 100%;
  min-height: 100%;
  font-family: hoss-round, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
#root {
  height: 100%;
  min-height: 100%;
}
